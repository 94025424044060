<template>
  <Content>
    <template slot="content">
        <market-place-header :urls="getPathUrls"/>

        <LoginPlug v-if="!isLoggedIn">
            <span>Connect your wallet<br> to see <br> your NFTS.</span>
        </LoginPlug>

        <template v-else>
            <Loader v-if="isListLoading"/>

            <template v-else>
                <template v-if="stakingShown">
                    <div class="cards-title">My Staking</div>
                        <vue-slick-carousel v-bind="getSliderSettings" class="staking-carousel">
                            <staking-nft-card
                                v-for="(stakingCharacter, i) in stakingCharacters"
                                :key="`staking-${i}`"
                                class="nft-cards__item"
                                ref="stakingElement"
                                :transitionDuration="i * 100"
                                :time="stakingCharacter.seconds_left"
                                v-bind="stakingCharacter.character"
                                @btn-click="onUnstakeNftClick(stakingCharacter)"
                            />

                            <template #prevArrow>
                                <BaseIcon :name="'pArrow'" class="p-arrow"/>
                            </template>
                            <template #nextArrow>
                                <BaseIcon :name="'pArrow'" class="n-arrow"/>
                            </template>
                        </vue-slick-carousel>
                </template>

                <template v-if="stakingShown">
                    <div class="cards-title cards-title--mynfts">All my characters</div>
                </template>

                <Pagination
                    v-show="pageSize < MY_CHARACTERS_COUNT"
                    :currentPage="Number(currentPage)"
                    :pageCount="pagesCount"
                    :navigationEnabled="true"
                    class="pagination-content"
                    @clickHandler="pageHandler"
                />

                <div v-if="GET_MY_CHARACTERS.length" class="nft-cards">
                    <my-nft-card
                        v-for="(character, i) in GET_MY_CHARACTERS"
                        :key="`myNft-${i}`"
                        class="nft-cards__item"
                        :image="character.mf_attributes.image"
                        :web_images="character.mf_attributes.web_images"
                        :type="character.mf_attributes.type"
                        :tier="character.mf_attributes.tier"
                        :level="character.mf_attributes.level"
                        :wins="character.mf_attributes.wins"
                        :losses="character.mf_attributes.losses"
                        :onSale="character.mf_attributes.status === 1"
                        :transitionDuration="i * 100"
                        v-bind="character"
                        @click="onCardClick(character)"
                        @btn-click="onBtnClick(character)"
                    />
                </div>
                <div v-else class="no-content-txt">
                    <span>There are no minted characters</span>
                </div>
            </template>
        </template>

        <preloader translucent black v-if="isProcessing" />
        <sell-price-popup ref="sellPricePopup"/>
        <transaction-success-popup ref="transactionSuccessPopup"/>
        <unstake-success-popup ref="unstakeSuccessPopup"/>
    </template>
  </Content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Content from '@/components/common/content.vue'
import MyNftCard from '@/components/common/my-nft-card.vue'
import StakingNftCard from '@/components/common/staking-nft-card.vue'
import MarketPlaceHeader from '@/components/market-place/header.vue'
import LoginPlug from '@/components/common/login-plug.vue'
import Loader from '@/components/common/loader.vue'
import Pagination from '@/components/common/pagination.vue'
import CharactersConfig from "@/constants/CharactersConfig";
import {calculatePagesCount} from "@/utils/helpers";
import stakeMixin from "@/mixins/stakeMixin";
import marketplaceMixin from "@/mixins/marketplaceMixin";

export default {
  name: 'MyNftList',
  mixins: [stakeMixin, marketplaceMixin],
  components: {
    Content,
    MyNftCard,
    StakingNftCard,
    MarketPlaceHeader,
    LoginPlug,
    Loader,
    Pagination,
  },
  computed: {
    ...mapGetters([
      'account',
      'isLoggedIn',
      'GET_IS_MOBILE',
      'GET_WINDOW_WIDTH',
      'GET_MY_CHARACTERS',
      'GET_STAKING_CHARACTERS',
      'MY_CHARACTERS_COUNT',
    ]),

    pagesCount() {
        return calculatePagesCount(this.pageSize, this.MY_CHARACTERS_COUNT);
    },

    stakingCharacters() {
        return this.GET_STAKING_CHARACTERS;
    },
    stakingShown() {
        return this.stakingCharacters.length && Number(this.currentPage) === 1;
    },
    getSliderSettings() {
        return {
            arrows: true,
            dots: true,
            rows: 1,
            slidesToShow: this.carouselItemsPerPage,
            "slidesToScroll": 1,
            "swipeToSlide": true,
            infinite: false,
        }
    },
    getPathUrls() {
        return [
            { label: 'Marketplace', name: 'Market' },
            { label: 'MY NFT', name: 'MyNfts' },
        ]
    },
  },
  data() {
      return {
          isListLoading: true,
          currentPage: null,
          pageSize: CharactersConfig.pageSize,
          stakingBlocks: [],
          carouselItemsPerPage: null,
      }
  },
  watch: {
      isLoggedIn(val) {
          if (val) {
              this.getMyCharacters();
          }
      },
      account(val) {
          if (val) {
              this.getMyCharacters();
          }
      },
      currentPage(val) {
          if (val) {
              this.getMyCharacters();
          }
      },
    },
    beforeMount() {
      this.setCarouselItemsCount();

      if (!this.$route.query.page) {
          this.pageHandler(1);
      } else {
          this.currentPage = this.$route.query.page;
      }
    },
    mounted() {
      window.addEventListener('resize', this.setCarouselItemsCount);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setCarouselItemsCount);
    },
    methods: {
        ...mapActions([
            'REQUEST_MY_CHARACTERS',
            'REQUEST_STAKING_CHARACTERS',
        ]),
        onCardClick(character) {
            this.$router.push({
                name: 'MyNftSingle',
                params: {
                  id: character.mf_attributes.token_id,
                }
            });
        },
        async getMyCharacters() {
            if (!this.isLoggedIn) {
                return;
            }

            this.isListLoading = true;

            try {
                const requestPromises = [];

                if (Number(this.currentPage) === 1) {
                    requestPromises.push(this.REQUEST_STAKING_CHARACTERS());
                }

                requestPromises.push(this.REQUEST_MY_CHARACTERS({page: this.currentPage}));

                await Promise.all(requestPromises);
            } catch {
                if (this.currentPage !== 1) {
                    this.pageHandler(1);
                }
            }

            this.isListLoading = false;

            this.setStakingBlocks();
        },
        setStakingBlocks() {
            const newArray = [];

            if (!this.GET_STAKING_CHARACTERS.length) {
                this.stakingBlocks = newArray;

                return;
            }

            for(let i = 1; i <= 5; i++){
                newArray.push({
                    character: {}
                });
            }

            this.stakingBlocks = newArray;
        },
        pageHandler(page) {
            const queryParams = new URLSearchParams(window.location.search);

            queryParams.set("page", String(page));
            history.pushState(null, null, "?"+queryParams.toString());
            this.currentPage = page;
        },
        onBtnClick(character) {
            if (character.mf_attributes.status === 1) {
                this.onCancelListingClick(character.mf_attributes.token_id);
            } else {
                this.onSellNftClick(character.mf_attributes.token_id);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.pagination-content {
    margin-top: 2em;
    justify-content: end;

    @media screen and (max-width: $bp_mb) {
        justify-content: center;
    }
}
.no-content-txt {
    color: white;
    margin-top: 5em;
    font-size: 24px;
    font-weight: 500;
}

.nft-cards--staking {
    .nft-cards__item {
        cursor: default;
    }
}
.cards-title {
    margin-top: 42px;
    color: white;
    font: 800 24px Raleway;
    text-transform: uppercase;

    @media screen and (max-width: $bp_mb) {
        text-align: center;

        &--mynfts {
            margin-top: 0;
        }
    }
}
</style>
