<template>
    <transition name="slide-fade" mode="out-in">
        <card-wrapper
            v-if="isMounted"
            contentClass="my-card__content"
            :image="image"
            :web-images="web_images"
            :level="level"
            :type="type"
            :tier="tier"
            class="my-card"
            @click="$emit('click')"
        >
            <template v-if="onSale" slot="media">
                <span class="sale-mark">on sale</span>
            </template>
            <template slot="content">
                <div class="my-card__title">
                    {{ name }}
                </div>

                <div class="my-card-info">
                    <div class="my-card__win-lose card-skew-back">
                        <div>Win: {{ wins }}</div>
                        <div>Lose: {{ losses }}</div>
                    </div>

                    <div class="my-card__tier card-skew-back">Tier: {{ Tiers[tier] }}</div>

                    <btn
                        type="mint-gold"
                        class="my-card__btn"
                        :text="onSale ? 'cancel order' : 'sell'"
                        :selected="price"
                        @click="$emit('btn-click')"
                    />
                </div>
            </template>
        </card-wrapper>
        <div class="bg-transparent" v-else></div>
    </transition>
</template>

<script>
import cardWrapper from './card-wrapper.vue';
import Tiers from "@/constants/Tiers";

export default {
    name: "MyNftCard",
    components: {
        cardWrapper,
    },
    props: {
        image: { type: String, default: '' },
        web_images: { type: Array, default: () => ([]) },
        level: { type: [String, Number], default: '' },
        name: { type: String, default: '' },
        type: { type: String, default: '' },
        price: { type: [String, Number], default: '' },
        stats: { type: Array, default: () => [] },
        wins: {type: Number, default: 0 },
        losses: {type: Number, default: 0 },
        tier: { type: Number, default: 0 },
        transitionDuration: { type: Number, default: 0},
        onSale: {type: Boolean, default: false},
    },
    data() {
        return {
            isMounted: false,
            Tiers,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, this.transitionDuration)
    }
}
</script>

<style lang="scss">

.my-card {
  font-size: 14px;
}
.my-card-info {
    padding: 10px 30px;
}
.my-card__content {
  padding-top: 10px;
  padding-bottom: 10px;
}
.my-card__title {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
}

.my-card__win-lose {
  display: flex;
  justify-content: space-between;
}

.my-card__tier {
  margin-top: 10px;
}

.my-card__btn {
  margin-top: 10px;
  padding: 10px 0;
  width: 100%;
}

.sale-mark {
    position: absolute;
    top: 9px;
    left: -33px;
    transform: rotate(-38deg) translateZ(99999px);
    padding: 3px 32px;
    background: orange;
    color: #1d1e36;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}
</style>
