<template>
    <transition name="slide-fade" mode="out-in">
        <card-wrapper
            v-if="isMounted"
            contentClass="staking-nft-card__content"
            :image="image"
            :web-images="web_images"
            :tier="1"
            :type="type"
            silhouette
            :class="['staking-nft-card', {'staking-nft-card--empty': !name}]"
            @click="$emit('click')"
        >
            <template slot="content">
                <div v-if="name"  class="staking-nft-card__title">
                    {{ name }}
                </div>

                <div v-if="name"  class="staking-nft-card__info">
                    <staking-timer :time="time" @end="timerEndHandler"/>

                    <btn
                        type="mint-gold"
                        class="nft-card__btn"
                        text="unstake + mint"
                        @click="$emit('btn-click')"
                        :disabled="unstakeDisabled"
                    />
                </div>
            </template>
        </card-wrapper>
        <div class="bg-transparent" v-else></div>
    </transition>
</template>

<script>
import StakingTimer from './staking-timer'
import cardWrapper from './card-wrapper.vue'

export default {
    name: "StakingNftCard",
    components: {
        cardWrapper,
        StakingTimer,
    },
    props: {
        image: { type: String, default: '' },
        web_images: { type: Array, default: () => ([]) },
        name: { type: String, default: '' },
        time: { type: [String, Number, Date], default: '' },
        type: { type: String, default: '' },
        transitionDuration: { type: Number, default: 0},
    },
    data() {
        return {
            isMounted: false,
            unstakeDisabled: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, this.transitionDuration);

        if (!this.time) {
            this.unstakeDisabled = false;
        }
    },
    methods: {
        timerEndHandler() {
            this.unstakeDisabled = false;
        },
    }
}
</script>

<style lang="scss">

.staking-nft-card {
    font-size: 14px;
    width: 243px!important;
    overflow: hidden;

    .card-wrapper__media {
        height: 245px;
    }

    &--empty {
        background-color: #16182a;
        opacity: 0.8;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            width: 10px;
            background: linear-gradient(to right, #ffffff21 -15%, transparent 50%);
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 10px;
            background: linear-gradient(to bottom, #ffffff21 -15%, transparent 50%);
        }
    }
}
.staking-nft-card__content {
    padding: 10px 0;
    height: 181px;
}
.staking-nft-card__info {
    padding: 0 26px;
}
.staking-nft-card__title {
    margin-bottom: 12px;
    font-size: 24px;
    text-align: center;
}

.staking-nft-card__btn {
    margin-top: 10px;
    padding: 10px 0;
    width: 100%;
}

.nft-card__btn {
    margin-top: 10px;
    padding: 10px 0;
    width: 100%;
}

</style>
