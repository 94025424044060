var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade", mode: "out-in" } },
    [
      _vm.isMounted
        ? _c(
            "card-wrapper",
            {
              class: [
                "staking-nft-card",
                { "staking-nft-card--empty": !_vm.name },
              ],
              attrs: {
                contentClass: "staking-nft-card__content",
                image: _vm.image,
                "web-images": _vm.web_images,
                tier: 1,
                type: _vm.type,
                silhouette: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("template", { slot: "content" }, [
                _vm.name
                  ? _c("div", { staticClass: "staking-nft-card__title" }, [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                    ])
                  : _vm._e(),
                _vm.name
                  ? _c(
                      "div",
                      { staticClass: "staking-nft-card__info" },
                      [
                        _c("staking-timer", {
                          attrs: { time: _vm.time },
                          on: { end: _vm.timerEndHandler },
                        }),
                        _c("btn", {
                          staticClass: "nft-card__btn",
                          attrs: {
                            type: "mint-gold",
                            text: "unstake + mint",
                            disabled: _vm.unstakeDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("btn-click")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _c("div", { staticClass: "bg-transparent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }