var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Content",
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("market-place-header", { attrs: { urls: _vm.getPathUrls } }),
          !_vm.isLoggedIn
            ? _c("LoginPlug", [
                _c("span", [
                  _vm._v("Connect your wallet"),
                  _c("br"),
                  _vm._v(" to see "),
                  _c("br"),
                  _vm._v(" your NFTS."),
                ]),
              ])
            : [
                _vm.isListLoading
                  ? _c("Loader")
                  : [
                      _vm.stakingShown
                        ? [
                            _c("div", { staticClass: "cards-title" }, [
                              _vm._v("My Staking"),
                            ]),
                            _c(
                              "vue-slick-carousel",
                              _vm._b(
                                {
                                  staticClass: "staking-carousel",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prevArrow",
                                        fn: function () {
                                          return [
                                            _c("BaseIcon", {
                                              staticClass: "p-arrow",
                                              attrs: { name: "pArrow" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "nextArrow",
                                        fn: function () {
                                          return [
                                            _c("BaseIcon", {
                                              staticClass: "n-arrow",
                                              attrs: { name: "pArrow" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1696351201
                                  ),
                                },
                                "vue-slick-carousel",
                                _vm.getSliderSettings,
                                false
                              ),
                              _vm._l(
                                _vm.stakingCharacters,
                                function (stakingCharacter, i) {
                                  return _c(
                                    "staking-nft-card",
                                    _vm._b(
                                      {
                                        key: `staking-${i}`,
                                        ref: "stakingElement",
                                        refInFor: true,
                                        staticClass: "nft-cards__item",
                                        attrs: {
                                          transitionDuration: i * 100,
                                          time: stakingCharacter.seconds_left,
                                        },
                                        on: {
                                          "btn-click": function ($event) {
                                            return _vm.onUnstakeNftClick(
                                              stakingCharacter
                                            )
                                          },
                                        },
                                      },
                                      "staking-nft-card",
                                      stakingCharacter.character,
                                      false
                                    )
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.stakingShown
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "cards-title cards-title--mynfts",
                              },
                              [_vm._v("All my characters")]
                            ),
                          ]
                        : _vm._e(),
                      _c("Pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.pageSize < _vm.MY_CHARACTERS_COUNT,
                            expression: "pageSize < MY_CHARACTERS_COUNT",
                          },
                        ],
                        staticClass: "pagination-content",
                        attrs: {
                          currentPage: Number(_vm.currentPage),
                          pageCount: _vm.pagesCount,
                          navigationEnabled: true,
                        },
                        on: { clickHandler: _vm.pageHandler },
                      }),
                      _vm.GET_MY_CHARACTERS.length
                        ? _c(
                            "div",
                            { staticClass: "nft-cards" },
                            _vm._l(
                              _vm.GET_MY_CHARACTERS,
                              function (character, i) {
                                return _c(
                                  "my-nft-card",
                                  _vm._b(
                                    {
                                      key: `myNft-${i}`,
                                      staticClass: "nft-cards__item",
                                      attrs: {
                                        image: character.mf_attributes.image,
                                        web_images:
                                          character.mf_attributes.web_images,
                                        type: character.mf_attributes.type,
                                        tier: character.mf_attributes.tier,
                                        level: character.mf_attributes.level,
                                        wins: character.mf_attributes.wins,
                                        losses: character.mf_attributes.losses,
                                        onSale:
                                          character.mf_attributes.status === 1,
                                        transitionDuration: i * 100,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCardClick(character)
                                        },
                                        "btn-click": function ($event) {
                                          return _vm.onBtnClick(character)
                                        },
                                      },
                                    },
                                    "my-nft-card",
                                    character,
                                    false
                                  )
                                )
                              }
                            ),
                            1
                          )
                        : _c("div", { staticClass: "no-content-txt" }, [
                            _c("span", [
                              _vm._v("There are no minted characters"),
                            ]),
                          ]),
                    ],
              ],
          _vm.isProcessing
            ? _c("preloader", { attrs: { translucent: "", black: "" } })
            : _vm._e(),
          _c("sell-price-popup", { ref: "sellPricePopup" }),
          _c("transaction-success-popup", { ref: "transactionSuccessPopup" }),
          _c("unstake-success-popup", { ref: "unstakeSuccessPopup" }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }