var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade", mode: "out-in" } },
    [
      _vm.isMounted
        ? _c(
            "card-wrapper",
            {
              staticClass: "my-card",
              attrs: {
                contentClass: "my-card__content",
                image: _vm.image,
                "web-images": _vm.web_images,
                level: _vm.level,
                type: _vm.type,
                tier: _vm.tier,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _vm.onSale
                ? _c("template", { slot: "media" }, [
                    _c("span", { staticClass: "sale-mark" }, [
                      _vm._v("on sale"),
                    ]),
                  ])
                : _vm._e(),
              _c("template", { slot: "content" }, [
                _c("div", { staticClass: "my-card__title" }, [
                  _vm._v(" " + _vm._s(_vm.name) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "my-card-info" },
                  [
                    _c(
                      "div",
                      { staticClass: "my-card__win-lose card-skew-back" },
                      [
                        _c("div", [_vm._v("Win: " + _vm._s(_vm.wins))]),
                        _c("div", [_vm._v("Lose: " + _vm._s(_vm.losses))]),
                      ]
                    ),
                    _c("div", { staticClass: "my-card__tier card-skew-back" }, [
                      _vm._v("Tier: " + _vm._s(_vm.Tiers[_vm.tier])),
                    ]),
                    _c("btn", {
                      staticClass: "my-card__btn",
                      attrs: {
                        type: "mint-gold",
                        text: _vm.onSale ? "cancel order" : "sell",
                        selected: _vm.price,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("btn-click")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _c("div", { staticClass: "bg-transparent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }