// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".my-card {\n  font-size: 14px;\n}\n.my-card-info {\n  padding: 10px 30px;\n}\n.my-card__content {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n.my-card__title {\n  margin-bottom: 15px;\n  font-size: 24px;\n  text-align: center;\n}\n.my-card__win-lose {\n  display: flex;\n  justify-content: space-between;\n}\n.my-card__tier {\n  margin-top: 10px;\n}\n.my-card__btn {\n  margin-top: 10px;\n  padding: 10px 0;\n  width: 100%;\n}\n.sale-mark {\n  position: absolute;\n  top: 9px;\n  left: -33px;\n  transform: rotate(-38deg) translateZ(99999px);\n  padding: 3px 32px;\n  background: orange;\n  color: #1d1e36;\n  text-transform: uppercase;\n  font-weight: 500;\n  font-size: 12px;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
