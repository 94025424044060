// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".staking-nft-card {\n  font-size: 14px;\n  width: 243px !important;\n  overflow: hidden;\n}\n.staking-nft-card .card-wrapper__media {\n  height: 245px;\n}\n.staking-nft-card--empty {\n  background-color: #16182a;\n  opacity: 0.8;\n}\n.staking-nft-card--empty:before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  top: 0;\n  width: 10px;\n  background: linear-gradient(to right, #ffffff21 -15%, transparent 50%);\n}\n.staking-nft-card--empty:after {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  height: 10px;\n  background: linear-gradient(to bottom, #ffffff21 -15%, transparent 50%);\n}\n.staking-nft-card__content {\n  padding: 10px 0;\n  height: 181px;\n}\n.staking-nft-card__info {\n  padding: 0 26px;\n}\n.staking-nft-card__title {\n  margin-bottom: 12px;\n  font-size: 24px;\n  text-align: center;\n}\n.staking-nft-card__btn {\n  margin-top: 10px;\n  padding: 10px 0;\n  width: 100%;\n}\n.nft-card__btn {\n  margin-top: 10px;\n  padding: 10px 0;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
